import React, { useState, useCallback, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { useTransition, animated } from '@react-spring/web';
import { useIntl } from 'react-intl';
import './Shop.css';
import BackButton from '../../components/BackButton/BackButton';
import { HandySvg } from 'handy-svg';
import Skins from './components/Skins/Skins';
import Levels from './components/Levels/Levels';
import Modal from '../../components/Modal/Modal';
import Background from '../../components/Background/Background';

const Shop = () => {
  const [shopSections, setShopSections] = useState([
    { type: 'skins', src: '/media/svg/mini_dino.svg', active: true },
    { type: 'levels', src: '/media/svg/level.svg', active: false }
  ]);

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalAlign, setModalAlign] = useState("center");
  const [modalPrice, setModalPrice] = useState(0);
  const [isOffDelivery, setIsOffDelivery] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [prevActive, setPrevActive] = useState('skins');
  const [modalButtonText, setModalButtonText] = useState("Ок"); // Добавлено состояние для текста кнопки

  const { userData, buylevel, buyOffDel } = useContext(MainContext);
  const intl = useIntl();
  
  const activeSection = shopSections.find(section => section.active);
  const transitions = useTransition(activeSection, {
    from: { opacity: 0, transform: `translate3d(${prevActive === 'skins' ? '100%' : '-100%'},0,0)` },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: `translate3d(${prevActive === 'skins' ? '-100%' : '100%'},0,0)` }
  });

  const onSelectSection = useCallback((section) => {
    setPrevActive(activeSection.type);
    setShopSections(prevSections =>
      prevSections.map(v => ({ ...v, active: v.type === section }))
    );
  }, [activeSection]);

  const openModal = (type, data = {}) => {
    const modalData = {
      info: {
        text: intl.formatMessage({ id: 'shop_info' }),
        isOffDelivery: false,
        align: "center",
        buttonText: "Ок"
      },
      cant_buy: {
        text: intl.formatMessage({ id: 'shop_cant_buy' }),
        isOffDelivery: false,
        align: "center",
        buttonText: "Ок"
      },
      ask_level_buy: {
        text: intl.formatMessage({ id: 'shop_ask_level_buy' }, { level: data.level }),
        isOffDelivery: false,
        align: "end",
        price: data.price,
        buttonText: intl.formatMessage({ id: 'buy' }, { level: data.level }),
      },
      ask_off_del_buy: {
        text: intl.formatMessage({ id: 'shop_ask_off_del_buy' }),
        isOffDelivery: true,
        align: "end",
        price: data.price,
        buttonText: intl.formatMessage({ id: 'buy' }, { level: data.level }),
      }
    };

    const { text, isOffDelivery: offDelivery, align, price, buttonText } = modalData[type] || {};
    if (text) {
        (type === 'info') ? setIsInfo(true) : setIsInfo(false)
        setModalText(text);
        setIsOffDelivery(offDelivery);
        setModalAlign(align);
        setModalButtonText(buttonText || "Ок"); // Установка текста кнопки
        setModalShow(true);
    }
  };

  const onBuy = (price, status, level = 1) => {
    if (status !== "buyed") {
      const activeSection = shopSections.find(v => v.active).type;
      setModalPrice(price)
      console.log(price)
      if (userData.score < price || userData.level + 1 < level) {
        openModal("cant_buy");
        return;
      }

      if (activeSection === "levels") {
        const modalType = level > 0 ? "ask_level_buy" : "ask_off_del_buy";
        openModal(modalType, { level, price });
      }
    }
  };

  const handlePurchase = () => {
    if (!isInfo) {
      console.log(userData.score, modalPrice)
      const isPurchaseValid = userData.score >= modalPrice;

      if (!isPurchaseValid) {
        setModalShow(false);
        return;
      } else {
        console.log(isPurchaseValid)
        if (isOffDelivery) {
          buyOffDel(modalPrice);
        } else {
          buylevel(modalPrice);
        }
      }

      
    }

    setModalShow(false);
  };

  return (
    <div className='shop_root'>
      <div className='shop_header'>
        <BackButton link='/' />
        <div className='shop_balance'>
          <HandySvg src='/media/svg/crystal.svg' />
          <p>{userData.score}</p>
        </div>
        <div className='shop_info'>
          <HandySvg src='/media/svg/info.svg' onClick={() => openModal("info")} />
        </div>
      </div>

      <div className='shop_wrap'>
        <div className='shop_content' style={{ position: 'relative', width: '100%', height: '100%' }}>
          {transitions((style, item) => (
            <animated.div style={{ ...style, position: 'absolute', width: '100%', height: '100%' }} key={item.type}>
              {item.type === 'skins' ? <Skins intl={intl} /> : <Levels onBuy={onBuy} level={userData.level} off_del={userData.off_del} intl={intl} />}
            </animated.div>
          ))}
        </div>

        <div className='shop_selector_wrap'>
          <div className='shop_selector'>
            {shopSections.map(v => (
              <div
                key={v.type}
                onClick={() => onSelectSection(v.type)}
                className={`shop_selector_item ${v.active ? 'active' : ''}`}
              >
                <HandySvg src={v.src} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        text={modalText}
        show={modalShow}
        align={modalAlign}
        onClose={() => setModalShow(false)}
        price={modalPrice}
        button={modalButtonText} // Передача текста кнопки в модальное окно
        onButtonClick={handlePurchase}
      />
      <Background/>
    </div>
  );
};

export default Shop;
