import React from 'react'
import './RatingItem.css'

const RatingItem = ({ index, photo, name, score, last = false }) => {
  return (
    <div className='refferalItem_root'>
        <div className="refferalItem_index_container">
            <span index={last ? "-1" : index} className="index_background"></span>
            <div className={`index ${last && "last"}`}>{index}</div>
        </div>
    
        <div class="refferalItem_user_info">
            <img class='image' src={photo ? photo : '/media/no_user_image.jpg'}/>
            <div class='name'>{name}</div>
            <div class='score_container'>
                <div class='score'>{score}</div>
            </div>
        </div>
    </div>
  )
}

export default RatingItem