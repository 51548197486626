// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/media/Background_up.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/media/Background_down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
    position: absolute;
    min-height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
}

.background_top {
    flex: 2 1;
    width: 100%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: bottom;
    background-size: auto 100%;
    background-repeat: repeat-x;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.background_bottom {
    flex: 1 1;
    width: 100%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-position: top;
    background-size: auto 100%;
    background-repeat: repeat-x;
}`, "",{"version":3,"sources":["webpack://./src/components/Background/Background.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,OAAO;IACP,MAAM;IACN,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,SAAO;IACP,WAAW;IACX,mDAA0D;IAC1D,2BAA2B;IAC3B,0BAA0B;IAC1B,2BAA2B;IAC3B,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,WAAW;IACX,mDAA4D;IAC5D,wBAAwB;IACxB,0BAA0B;IAC1B,2BAA2B;AAC/B","sourcesContent":[".background {\r\n    position: absolute;\r\n    min-height: 100%;\r\n    width: 100%;\r\n    left: 0;\r\n    top: 0;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    z-index: 0;\r\n}\r\n\r\n.background_top {\r\n    flex: 2;\r\n    width: 100%;\r\n    background: url('../../../public/media/Background_up.png');\r\n    background-position: bottom;\r\n    background-size: auto 100%;\r\n    background-repeat: repeat-x;\r\n    position: relative;\r\n    z-index: 3;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.background_bottom {\r\n    flex: 1;\r\n    width: 100%;\r\n    background: url('../../../public/media/Background_down.png');\r\n    background-position: top;\r\n    background-size: auto 100%;\r\n    background-repeat: repeat-x;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
