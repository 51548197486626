// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backButton_root {
    width: 65px;
    height: 46px;
    background: #331C12;
    border-radius: 19px;
    box-shadow: 0px 4px 2px 0px #00000080;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backButton_root svg{
    width: 35px;
    height: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components/BackButton/BackButton.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,qCAAqC;IACrC,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".backButton_root {\r\n    width: 65px;\r\n    height: 46px;\r\n    background: #331C12;\r\n    border-radius: 19px;\r\n    box-shadow: 0px 4px 2px 0px #00000080;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.backButton_root svg{\r\n    width: 35px;\r\n    height: 35px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
