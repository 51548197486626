import React, { useState } from 'react'
import './Levels.css'
import { HandySvg } from 'handy-svg'

const LevelItem = ({ level, status, price, onClick, intl }) => {
    return (
        <div className={`level_item_root ${status}`}>
            <div className='level_item_svg'>
                <HandySvg src='/media/svg/level.svg' />
            </div>
            <p>{`${level} ${intl.formatMessage({ id: 'level' })}`}</p>
            <div onClick={onClick} className='level_item_price'>
                {(status !== "buyed") &&
                    <>
                        <HandySvg src='/media/svg/crystal.svg' />
                        <p>{price}</p>
                    </>
                }
                {(status === "buyed") && <p>{`${intl.formatMessage({ id: 'bought' })}`}</p>}
            </div>
        </div>
    )
}

const Levels = ({ level, onBuy, off_del, intl }) => {
    const [levels, setLevels] = useState([
        { level: 1, price: 100 },
        { level: 2, price: 1000 },
        { level: 3, price: 2000 },
        { level: 4, price: 5000 },
        { level: 5, price: 10000 },
        { level: 6, price: 20000 },
        { level: 7, price: 50000 },
        { level: 8, price: 100000 },
        { level: 9, price: 1000000 },
    ])

  return (
    <div className='levels_root'>
        <div onClick={() => onBuy(50000, off_del === 1 ? "buyed" : "", -1)} className={`level_offdel ${(off_del === 1) ? 'buyed' : ''}`}>
            <p>{`${intl.formatMessage({ id: 'offline_delivery' })}`}</p>
            { (off_del === 0) && <span className='level_offdel_price'>
                <p>50 000</p>
                <HandySvg src='/media/svg/crystal.svg' />
            </span>}
        </div>
        <div className='levels_container'>
            {
                levels.map(v => {
                    let status = ""
                    if (level >= v.level) status = "buyed"
                    if (level + 1 === v.level) status = "can_buy"
                    return(
                        <LevelItem
                            level={v.level}
                            price={v.price}
                            status={status}
                            intl={intl}
                            onClick={() => onBuy(v.price, status, v.level)}
                        />
                    )
                })
            }
        </div>
    </div>
  )
}

export default Levels