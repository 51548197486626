import React from 'react'
import { HandySvg } from 'handy-svg'
import './Loading.css'

const Loading = () => {
  return (
    <div className='loading_root'>
        <div class="loader">
            <div className='loader_crystal'>
                <HandySvg src='/media/svg/crystal.svg' />
            </div>
            <div className='loader_crystal'>
                <HandySvg src='/media/svg/crystal.svg' />
            </div>
            <div className='loader_crystal'>
                <HandySvg src='/media/svg/crystal.svg' />
            </div>
            <div className='loader_crystal'>
                <HandySvg src='/media/svg/crystal.svg' />
            </div>
            <div className='loader_crystal'>
                <HandySvg src='/media/svg/crystal.svg' />
            </div>
            <div className='loader_crystal'>
                <HandySvg src='/media/svg/crystal.svg' />
            </div>
        </div>
        <p>Loading</p>
    </div>
  )
}

export default Loading