import React from 'react'
import './Bubble.css'
import { HandySvg } from 'handy-svg'

const Bubble = ({add}) => {
  return (
    <div className='bubble_root'>
        <img src='/media/bubble_crystal.png' alt='bubble' />
        <div className='bubble_number'>
            <p>+{add}</p>
        </div>
        
    </div>
  )
}

export default Bubble