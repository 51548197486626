import React from 'react'
import { HandySvg } from 'handy-svg'
import { useNavigate } from 'react-router-dom'
import './BackButton.css'

const BackButton = ({ link }) => {

    const navigate = useNavigate()

  return (
    <div onClick={() => navigate(link)} className='backButton_root'>
        <HandySvg src='/media/svg/arrow_back.svg'/>
    </div>
  )
}

export default BackButton