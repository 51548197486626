import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HandySvg } from 'handy-svg';
import { useIntl } from 'react-intl'
import BackButton from '../../components/BackButton/BackButton';
import { MainContext } from '../../contexts/MainContext';
import RatingItem from '../../components/RatingItem/RatingItem';
import { BACKEND_URL } from '../../config';
import './Referral.css';
import Background from '../../components/Background/Background';

const Referral = () => {
  const [ratingList, setRatingList] = useState([]);
  const [lastUser, setLastUser] = useState(undefined);

  const intl = useIntl();

  const [selectors, setSelectors] = useState({
    first: [
      { value: 'referral', name: intl.formatMessage({ id: 'referrals' }), active: true },
      { value: 'income', name: intl.formatMessage({ id: 'income' }), active: false }
    ],
    second: [
      { value: 'this_week', name: intl.formatMessage({ id: 'this_week' }), active: true },
      { value: 'last_week', name: intl.formatMessage({ id: 'last_week' }), active: false },
      { value: 'all_time', name: intl.formatMessage({ id: 'all_time' }), active: false }
    ]
  });

  const navigate = useNavigate();
  const { userData, setModalShow, setModalText } = useContext(MainContext);

  const handleSelectorClick = useCallback((value, type) => {
    setSelectors(prev => ({
      ...prev,
      [type]: prev[type].map(item => ({
        ...item,
        active: item.value === value
      }))
    }));
  }, []);

  useEffect(() => {
    const activeFirst = selectors.first.find(v => v.active).value;
    const activeSecond = selectors.second.find(v => v.active).value;

    fetch(`${BACKEND_URL}/rating?` + new URLSearchParams({ type: activeFirst, interval: activeSecond }), {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
      .then(response => response.json())
      .then(result => {
        setRatingList(result);
        setLastUser(result.find(v => v.tgid === userData.tgid));
        console.log(result.find(v => v.tgid === userData.tgid))
      });
  }, [selectors]);
  
  const showInfoModal = () => {
    setModalText(intl.formatMessage({ id: 'modal_referral' }))
    setModalShow(true)
  }

  return (
    <div className='referral_root'>
      <div className='referral_header'>
        <div className='refferal_top_header'>
          <BackButton link='/' />
          <div className='referral_invite'>
            <HandySvg onClick={() => navigate("/invite")} src='/media/svg/invite.svg' />
          </div>
          <div className='referral_balance'>
            <HandySvg src='/media/svg/crystal.svg' />
            <p>{userData.score}</p>
          </div>
          <div onClick={showInfoModal} className='referral_info'>
            <HandySvg src='/media/svg/info.svg' />
          </div>
        </div>
      </div>


      <div className='refferal_rating_wrap'>
        <div className='referral_selector'>
          <div className='refferal_first_selector'>
            {selectors.first.map(v => (
              <p
                key={v.value}
                onClick={() => handleSelectorClick(v.value, 'first')}
                className={`selector ${v.active ? 'active' : ''}`}
              >
                {v.name}
              </p>
            ))}
          </div>
          <div className='refferal_second_selector'>
            {selectors.second.map(v => (
              <p
                key={v.value}
                onClick={() => handleSelectorClick(v.value, 'second')}
                className={`selector ${v.active ? 'active' : ''}`}
              >
                {v.name}
              </p>
            ))}
          </div>
        </div>
        <div className='referral_list_wrap'>
          <div className='refferal_rating'>
            {ratingList.length > 0 ? (
              ratingList.map(v => (
                <RatingItem
                  key={v.position}
                  index={v.position}
                  name={v.name}
                  photo={v.photo}
                  score={ (selectors.first.find(k => k.active).value === 'referral') ? v.referrals : v.score}
                />
              ))
            ) : (
              <div className='empty'><p>{`${intl.formatMessage({ id: 'empty' })}`}</p></div>
            )}
          </div>

          {ratingList.length > 0 && lastUser && (
            <div className='refferal_last'>
              <div className='upper_line' />
              <RatingItem
                index={lastUser.position}
                name={lastUser.name}
                photo={lastUser.photo}
                score={ (selectors.first.find(v => v.active).value === 'referral') ? lastUser.referrals : lastUser.score}
                last
              />
            </div>
          )}
        </div>
      </div>
      <Background/>
    </div>
  );
};

export default Referral;
