import React, { useState, useContext, useEffect, useRef } from 'react'
import './Main.css'
import { HandySvg } from 'handy-svg'
import {animated, useSpring} from 'react-spring'
import {useNavigate} from 'react-router-dom'
import Modal from '../../components/Modal/Modal'
import Background from '../../components/Background/Background'
import { MainContext } from '../../contexts/MainContext'
import { useIntl } from 'react-intl'
import Bubble from '../../components/Bubble/Bubble'

const FloatingNumber = ({ x, y, number, onRemove }) => {
  const props = useSpring({
    from: { transform: 'translate3d(0px, 0px, 0px)', opacity: 1 },
    to: { transform: 'translate3d(0px, -50px, 0px)', opacity: 0 },
    config: { duration: 1000 }, 
    onRest: onRemove
  });

  return (
    <animated.div
      style={{
        ...props,
        position: 'absolute',
        top: y * 0.8,
        left: x,
        color: 'white',
        pointerEvents: 'none',
        fontWeight: 'bold',
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        fontSize: "16px"
      }}
    >
      <Bubble add={number}/>
    </animated.div>
  );
};

const Main = () => {
  const [clicks, setClicks] = useState([]);
  const [bottomOffset, setBottomOffset] = useState(0);

  const imgRef = useRef(null);
  

  const {userData, setModalShow, setModalText, scoreClick} = useContext(MainContext)

  const navigate = useNavigate()

  const intl = useIntl();

  useEffect(() => {
    const imgElement = imgRef.current;

    // Функция для вычисления отступа на основе текущей высоты
    const calculateOffset = () => {
      if (imgElement) {
        const imgHeight = imgElement.offsetHeight;
        let calculatedOffset = imgHeight * -0.143; // 10% от высоты
        if (userData) {
          switch (userData.level) {
            case 5:
              calculatedOffset = imgHeight * -0.07;
              break;
            case 6:
              calculatedOffset = imgHeight * -0.114;
              break;
            case 7:
              calculatedOffset = imgHeight * -0.28125;
              break;
            case 8:
              calculatedOffset = imgHeight * 0.046;
              break;
            case 9:
              calculatedOffset = imgHeight * 0.046;
              break;
          }
        }
        setBottomOffset(calculatedOffset);
      }
    };

    // Создаем наблюдатель за изменением размеров
    const resizeObserver = new ResizeObserver(() => {
      calculateOffset(); // Пересчитываем отступ при изменении размеров
    });

    if (imgElement) {
      resizeObserver.observe(imgElement); // Подписываемся на изменения размеров элемента
    }

    // Отписываемся от наблюдения при размонтировании компонента
    return () => {
      if (imgElement) {
        resizeObserver.unobserve(imgElement);
      }
    };
  }, [imgRef]);

  const openModal = (info) => {
    switch (info) {
      case "crystal":
        setModalText(intl.formatMessage({ id: 'modal_tap' }))
        break;
      case "level":
        setModalText(intl.formatMessage({ id: 'modal_level' }))
        break;
    }
    setModalShow(true)
  }

  const handleClick = (event) => {
    if (parseInt(userData.energy) > 0) {
      const x = event.clientX;
      const y = event.clientY;

      scoreClick()

      setClicks((prev) => [
        ...prev,
        {
          id: Date.now(),
          x,
          y,
          number: userData ? userData.level : 1
        }
      ]);
    }
  };

  const removeClick = (id) => {
    setClicks((prev) => prev.filter((click) => click.id !== id));
  };

  return (
    <div class="main_root">
      <div className='main_header'>
        <div className='main_header_container taps'>
          <HandySvg className='crystal' src='/media/svg/crystal.svg' />
          <p>+{userData && userData.level}</p>
          <HandySvg onClick={() => openModal("crystal")} className='info' src='/media/svg/info.svg'/>
        </div>
        <div className='main_header_container level'>
          <HandySvg className='dino_shadow' src='/media/svg/dino_shadow.svg' />
          <p>{`${userData && userData.level} ${intl.formatMessage({ id: 'level' })}`}</p>
          <HandySvg onClick={() => openModal("level")} className='info' src='/media/svg/info.svg'/>
        </div>
        <div onClick={() => navigate("settings")} className='main_header_container settings'>
          <HandySvg className="settings" src='/media/svg/settings.svg'/>
        </div>
      </div>
      <div className={`main_body ${(userData && userData.level === 6) && 'car'}`} style={{ justifyContent: `${userData && userData.level > 6 ? "flex-start" : "flex-end"}`}}>
        <Background>
          <img ref={imgRef} style={{ bottom: bottomOffset}} onClick={handleClick} src={`/media/gif/${userData && (userData.level > 9 ? 9 : userData.level)}.gif`} alt='dino' />
        </Background>
        {clicks.map((click) => (
          <FloatingNumber key={click.id} x={click.x} y={click.y} number={click.number} onRemove={() => removeClick(click.id)} />
        ))}
      </div>

      <div className='main_footer'>
        <div className='main_user_info'>
          <div className='user_info energy'>
            <HandySvg src='/media/svg/lightning.svg' />
            <div className='user_info_container'>
              <p>{userData && parseInt(userData.energy)}/{(userData ? userData.level : 1) * 100}</p>
            </div>
          </div>
          <div className='user_info crystals'>
            <HandySvg src='/media/svg/crystal.svg' />
            <div className='user_info_container'>
              <p>{ userData && userData.score}</p>
            </div>
          </div>
        </div>
    
        <div className='main_nav_buttons'>
          <div onClick={() => navigate("/tasks")} className='main_nav_button'>
            <HandySvg src='/media/svg/task.svg'/>
            <p>{`${intl.formatMessage({ id: 'tasks' })}`}</p>
          </div>
          <div onClick={() => navigate("/shop")} className='main_nav_button'>
            <HandySvg src='/media/svg/market.svg'/>
            <p>{`${intl.formatMessage({ id: 'shop' })}`}</p>
          </div>
          <div onClick={() => navigate("/referral")} className='main_nav_button'>
            <HandySvg src='/media/svg/referral.svg'/>
            <p>{`${intl.formatMessage({ id: 'referral' })}`}</p>
          </div>
        </div>
      </div>

      <div className='custom'>

      </div>
    </div>
  )
}

export default Main