import { LOCALES } from './locales'

export const messages = {
  [LOCALES.ENGLISH]: {
    level: `level`,
    tasks: `Tasks`,
    shop: `Shop`,
    referral: `Referral program`,
    modal_tap: `The number of gems per tap. Increase levels to get more gems per tap`,
    modal_level: `Each level unlocks a new animation, +1 gem per tap and gives more energy. Energy is fully replenished in an hour`,
    modal_referral: 'There are 2 player tops presented here. The referral top is the number of friends invited. In order for a friend to be counted, it is necessary that he has accumulated at least 51 gems',
    language: "Language",
    setting_giveaway: "Enable notification of the start of distribution on the site",
    setting_site: "Enable daily marker notification on the site",
    referrals: 'Referrals',
    income: 'Income',
    this_week: 'This week',
    last_week: 'Last week',
    all_time: 'All time',
    empty: "Empty",
    invite_friend: 'Invite friend',
    for_friend: 'for friend',
    for_premium_friend: 'for friend with Telegram Premium',
    no_friends: "You don't have any friends yet",
    skin_selected: 'Selected',
    offline_delivery: 'Offline delivery',
    bought: 'bought',
    shop_info: "You can buy a new level here, as well as an offline delivery service that delivers gems every hour while you're on vacation",
    shop_cant_buy: "You can't buy it now",
    shop_ask_level_buy: 'Purchase a {level} level for a',
    shop_ask_off_del_buy: 'Purchase offline shipping for',
    rotate_phone: 'Please turn the device to portrait mode',
    invite_text: 'HELP GDZAVRIK GEMS 💎 AND EARN MOONS 🌙 WITH ME! official bot https://genshindrop.top/gdshop',
    task_mark: "Go to the marks on the site",
    task_giveaway: "Go to distributions on the site",
    gdzavr: 'GDzavrik',
    buy: 'Buy',
  },
  [LOCALES.RUSSIAN]: {
    level: 'уровень',
    tasks: `Задания`,
    shop: `Магазин`,
    referral: `Реферальная программа`,
    modal_tap: `Количество гемов за один тап. Повышай уровни, чтобы получать больше гемов за тап`,
    modal_level: `Каждый уровень разблокирует новую анимацию, +1 гем за каждый тап и дает больше энергии. Энергия восполняется полностью за час`,
    modal_referral: 'Здесь представлено 2 топа игроков. Топ по рефералам - это количество приглашенных друзей. Чтобы друг засчитался, необходимо, чтобы он натапал не меньше 51 гема',
    language: "Язык",
    setting_giveaway: "Включить оповещение о запуске раздачи на сайте",
    setting_site: "Включить оповещение об ежедневной отметке на сайте",
    referrals: 'Рефералы',
    income: 'Доход',
    this_week: 'Текущая неделя',
    last_week: 'Предыдущая неделя',
    all_time: 'Все время',
    empty: "Пусто",
    invite_friend: 'Пригласить друга',
    for_friend: 'За друга',
    for_premium_friend: 'За друга с Telegram Premium',
    no_friends: 'У вас пока нет друзей',
    skin_selected: 'Выбрано',
    offline_delivery: 'Оффлайн-доставка',
    bought: 'куплено',
    shop_info: 'Здесь можно купить новый уровень, а так же оффлайн-доставку, которая доставляет гемы каждый час, пока ты отдыхаешь',
    shop_cant_buy: 'Вы не можете купить это сейчас',
    shop_ask_level_buy: 'Приобрести {level} уровень за',
    shop_ask_off_del_buy: 'Приобрести оффлайн-доставку за', 
    rotate_phone: 'Пожалуйста, переверните устройство в портретный режим',
    invite_text: 'ПОМОГИ ГДЗАВРИКУ ДОСТАВИТЬ ГЕМЫ 💎 И ЗАРАБОТАЙ ЛУНЫ 🌙 ВМЕСТЕ СО МНОЙ! официальный бот https://genshindrop.top/gdshop',
    task_mark: "Перейти к отметкам на сайте",
    task_giveaway: "Перейти к раздачам на сайте",
    gdzavr: 'ГДзаврик',
    buy: 'Купить',
  },
}