import React, {useState} from 'react'
import { BACKEND_URL } from '../../../../config'
import { HandySvg } from 'handy-svg';
import './Skins.css'

const SkinItem = ({ name, price, src }) => {
    return (
      <div className='skins_item_wrap'>
        <div className='skins_item'>
          <div className='skins_item_price_wrap'>
            { price !== 0 && <div className='skins_item_price'>
              
              </div>
            }
  
            { price === -1 && <div className='skins_item_buyed'>
                <HandySvg src='/media/svg/check.svg' />
              </div>
            }
          </div>
          <img src={BACKEND_URL + "/images" + src} />
        </div>
        <div className='skins_item_name'>
          <p>{name}</p>
        </div>
      </div>
    )
  }

const Skins = ({intl}) => {

    const [skins, setSkins] = useState([
        { name: intl.formatMessage({ id: 'gdzavr' }), price: -1, src: "/skins/gdzavr.png"}
      ])
    
      return (
        <div className='skins_root'>
          {
            skins.map(v => {
              return(
                <SkinItem name={v.name} src={v.src} price={v.price} />
              )
            })
          }
          <div className='skins_selector'>
            <p>{`${intl.formatMessage({ id: 'skin_selected' })}`}</p>
          </div>
        </div>
      );
}

export default Skins