import { useContext, useEffect, useState } from "react";
import { MainContext } from "./contexts/MainContext";
import { Route, Routes, useLocation } from "react-router-dom";
import { IntlProvider, FormattedMessage } from 'react-intl';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
import Main from "./pages/Main/Main";
import Settings from "./pages/Settings/Settings";
import Shop from "./pages/Shop/Shop";
import Referral from "./pages/Referral/Referral";
import Invite from "./pages/Invite/Invite";
import Tasks from "./pages/Tasks/Tasks";
import { useTransition, animated } from 'react-spring';
import './App.css';
import { HandySvg } from "handy-svg";

function App() {
  const { userData } = useContext(MainContext);
  const location = useLocation();
  
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const locales = {
    "en": LOCALES.ENGLISH,
    "ru": LOCALES.RUSSIAN
  };

  // Функция для проверки ориентации экрана
  const checkOrientation = () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  // Анимация переходов
  const transitions = useTransition(location, {
    from: {
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%',
      transform: 
        location.pathname === "/" || 
        (location.pathname === "/referral" && location.state?.from === "/invite") 
          ? 'translateX(-100%)' 
          : 'translateX(100%)'
    },
    enter: { 
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: 'translateX(0%)',
      flexGrow: 1,
      display: "flex"
    },
    leave: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: 
        location.pathname === "/" || 
        (location.pathname === "/referral" && location.state?.from === "/invite") 
          ? 'translateX(100%)' 
          : 'translateX(-100%)'
    },
  });

  return (
    <IntlProvider
      messages={messages[locales[userData?.lang] || LOCALES.RUSSIAN]}
      locale={locales[userData?.lang] || LOCALES.RUSSIAN}
      defaultLocale={LOCALES.RUSSIAN}
    >
      <div id="root-wrapper" style={{ position: 'relative', flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {isLandscape ? (
          // Отображаем сообщение при landscape-ориентации
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            
            color: 'black',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999
          }}>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px'
            }}>
              <HandySvg src="/media/svg/rotate_phone.svg" />
              <FormattedMessage id="rotate_phone"/>
            </div>
          </div>
        ) : (
          transitions((props, item) => (
            <animated.div style={{ ...props }}>
              <Routes location={item}>
                <Route path="/" element={<Main />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route 
                  path="/referral" 
                  element={<Referral />} 
                  state={{ from: location.pathname }}
                />
                <Route 
                  path="/invite" 
                  element={<Invite />} 
                  state={{ from: location.pathname }}
                />
              </Routes>
            </animated.div>
          ))
        )}
      </div>
    </IntlProvider>
  );
}

export default App;
