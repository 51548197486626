// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
     -webkit-user-select: none;
             user-select: none;
     z-index: 2;
}

p, h1, h2, h3, h4 {
  font-family: Bahnschrift;
  margin: 0;
}

img {
  user-drag: none; /* For Firefox */
  -webkit-user-drag: none; /* For Chrome and Safari */
}

html, body {
  min-height: 100svh;
  min-width: 100svw;
  margin: 0; /* Удаляем отступы */
}

html {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  background-color: #020200;
}

body {
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #c0f3ff;
  position: relative;
  touch-action: none !important;
  -webkit-overflow-scrolling: touch !important;
  
  height: 100%;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
  min-height: 100vh;
}

#root-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IAGK,sBAAsB;KACtB,yBAAiB;aAAjB,iBAAiB;KACjB,UAAU;AACf;;AAEA;EACE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,eAAe,EAAE,gBAAgB;EACjC,uBAAuB,EAAE,0BAA0B;AACrD;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,SAAS,EAAE,oBAAoB;AACjC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAElB,6BAA6B;EAC7B,4CAA4C;;EAE5C,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;;EAEtB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;;EAEtB,iBAAiB;AACnB","sourcesContent":["* {\n    -moz-box-sizing: border-box;\n    -webkit-box-sizing: border-box;\n     box-sizing: border-box;\n     user-select: none;\n     z-index: 2;\n}\n\np, h1, h2, h3, h4 {\n  font-family: Bahnschrift;\n  margin: 0;\n}\n\nimg {\n  user-drag: none; /* For Firefox */\n  -webkit-user-drag: none; /* For Chrome and Safari */\n}\n\nhtml, body {\n  min-height: 100svh;\n  min-width: 100svw;\n  margin: 0; /* Удаляем отступы */\n}\n\nhtml {\n  min-height: 100svh;\n  display: flex;\n  flex-direction: column;\n  background-color: #020200;\n}\n\nbody {\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  background-color: #c0f3ff;\n  position: relative;\n  -ms-touch-action: none !important;\n  touch-action: none !important;\n  -webkit-overflow-scrolling: touch !important;\n  \n  height: 100%;\n}\n\n#root {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  \n  min-height: 100vh;\n}\n\n#root-wrapper {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  \n  min-height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
