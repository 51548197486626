import React from 'react'
import './Background.css'

const Background = ({children}) => {
  return (
    <div className='background'>
        <div className='background_top'>
            {children}
        </div>
        <div className='background_bottom'></div>
    </div>
  )
}

export default Background