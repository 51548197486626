import React, { useState, useContext, useEffect, useCallback } from 'react';
import { HandySvg } from 'handy-svg';
import BackButton from '../../components/BackButton/BackButton';
import { MainContext } from '../../contexts/MainContext';
import RatingItem from '../../components/RatingItem/RatingItem';
import { useIntl } from 'react-intl'
import { BOT_URL, BACKEND_URL } from '../../config';
import './Invite.css';
import Background from '../../components/Background/Background';

const Invite = () => {
  const [referralList, setReferralList] = useState([]);
  const { userData } = useContext(MainContext);

  const intl = useIntl();

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/referrals?` + new URLSearchParams({ tgid: userData?.tgid }), {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        });
        const result = await response.json();
        setReferralList(result);
      } catch (error) {
        console.error('Failed to fetch referrals:', error);
      }
    };

    if (userData?.tgid) {
      fetchReferrals();
    }
  }, [userData?.tgid]);

  const onInviteFriend = useCallback(() => {
    const userId = window.Telegram.WebApp?.initDataUnsafe?.user?.id || 440311516;
    const message = `${BOT_URL}?start=${userId}`;
    const url = `https://t.me/share/url?url=${encodeURIComponent(message)}&text=${intl.formatMessage({ id: 'invite_text' })}!`;

    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }, []);

  return (
    <div className='invite_root'>
      <div className='invite_user'>
        <BackButton link='/referral' />
        <div className='invite_income'>
          <HandySvg src='/media/svg/crystal.svg' />
          <p>{userData?.score}</p>
        </div>
      </div>

      <div className='invite_info'>
        <div className='invite_info_wrap'>
          <div className='invite_invite'>
            <p>{`${intl.formatMessage({ id: 'invite_friend' })}`}</p>
            <div onClick={onInviteFriend} className='invite_button'>
              <HandySvg src='/media/svg/invite.svg' />
            </div>
          </div>

          <div className='invite_prices'>
            <div className='invite_price_container'>
              <div className='invite_price'>
                <HandySvg src='/media/svg/crystal.svg' />
                <p>500</p>
              </div>
              <p>{`${intl.formatMessage({ id: 'for_friend' })}`}</p>
            </div>
            <div className='invite_price_container'>
              <div className='invite_price'>
                <HandySvg src='/media/svg/crystal.svg' />
                <p>2000</p>
              </div>
              <p>{`${intl.formatMessage({ id: 'for_premium_friend' })}`}</p>
            </div>
          </div>
        </div>


        <div className='invite_list'>
          {referralList.length === 0 ? (
            <p className='no_friends'>{`${intl.formatMessage({ id: 'no_friends' })}`}</p>
          ) : (
            referralList.map(v => (
              <RatingItem
                key={v.position}
                index={v.position}
                name={v.name}
                photo={v.photo}
                score={v.score}
              />
            ))
          )}
        </div>
      </div>
      <Background/>
    </div>
  );
};

export default Invite;
