import React, {useState, useContext, useEffect} from 'react'
import { useSpring, animated } from 'react-spring';
import { useIntl } from 'react-intl'
import { MainContext } from '../../contexts/MainContext';
import './Settings.css'
import BackButton from '../../components/BackButton/BackButton'
import CheckBox from '../../components/CheckBox/CheckBox'
import Background from '../../components/Background/Background';

const Settings = () => {

    const languages = {
        "ru": "Русский",
        "en": "English"
    }

    const { userData, changeLanguage, changeNotifications } = useContext(MainContext)

    const [selLang, setSelLang] = useState(languages[userData.lang])
    const [openLang, setOpenLang] = useState(false)

    const intl = useIntl();
    

    useEffect(() => {
        const languages = {
            "Русский": "ru",
            "English": "en"
        }
        changeLanguage(languages[selLang])
    }, [selLang])

    const dropdownAnimation = useSpring({
        
        maxHeight: openLang ? 150 : 0, // Установите maxHeight в зависимости от содержимого
        overflow: 'hidden',
        config: { tension: 170, friction: 26 },
    });

  return (
    <div className='settings_root'>
        <div class='settings_header'>
            <BackButton link="/"/>
        </div>
        <div class="settings">
           {/* <div class="language_container">
                <div class='setting language'>
                    <div class='text'>{`${intl.formatMessage({ id: 'language' })}`}</div>
                    <div onClick={() => setOpenLang(v => !v)} class='setting_language_list'>
                        <p class="setting_language_selected">{selLang}</p>
                    </div>
                </div>
                <animated.div style={dropdownAnimation} class={`setting_language_values`}>
                    <div onClick={() => {setOpenLang(false); setSelLang("Русский")}} class="setting_language_value ru">
                        <p>Русский</p>
                    </div>
                    <div onClick={() => {setOpenLang(false); setSelLang("English")}} class="setting_language_value en">
                        <p>English</p>
                    </div>
                    <div class="setting_language_empty">
                        <div></div>
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </animated.div>
            </div>*/}
            <div class='setting alert'>
                <div class='text'>
                    {`${intl.formatMessage({ id: 'setting_giveaway' })}`}
                </div>
                <CheckBox onChange={() => changeNotifications(true)} active={userData.notif_giveaway}/>
            </div>
            <div class='setting alert'>
                <div class='text'>
                    {`${intl.formatMessage({ id: 'setting_site' })}`}
                </div>
                <CheckBox onChange={() => changeNotifications(false)} active={userData.notif_site}/>
            </div>
            <div class=''></div>
        </div>
        <Background/>
    </div>
  )
}

export default Settings