// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/media/bubble_number.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubble_root {
    min-width: 70px;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.bubble_number {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -5px;
    bottom: -65px;

}

.bubble_number p{
    color: #0365A4;
    font-size: 16px;
    filter: drop-shadow(0px 1px 0px #fff);
}`, "",{"version":3,"sources":["webpack://./src/components/Bubble/Bubble.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mDAA0D;IAC1D,4BAA4B;IAC5B,4BAA4B;IAC5B,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,aAAa;;AAEjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,qCAAqC;AACzC","sourcesContent":[".bubble_root {\r\n    min-width: 70px;\r\n    min-height: 70px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    \r\n}\r\n\r\n.bubble_number {\r\n    min-width: 30px;\r\n    min-height: 30px;\r\n    border-radius: 50%;\r\n    background: url('../../../public/media/bubble_number.png');\r\n    background-repeat: no-repeat;\r\n    background-position: 50% 50%;\r\n    background-size: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    position: absolute;\r\n    left: -5px;\r\n    bottom: -65px;\r\n\r\n}\r\n\r\n.bubble_number p{\r\n    color: #0365A4;\r\n    font-size: 16px;\r\n    filter: drop-shadow(0px 1px 0px #fff);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
