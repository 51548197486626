import React, { useState, useEffect, useContext } from 'react'
import {MainContext} from '../../contexts/MainContext'
import './Tasks.css'
import BackButton from '../../components/BackButton/BackButton'
import { HandySvg } from 'handy-svg'
import { BACKEND_URL } from '../../config'
import Background from '../../components/Background/Background'
import { useIntl } from 'react-intl'

const Timer = ({ targetHour, targetMinute, tms }) => {
  // Локальное состояние для времени, которое будет увеличиваться каждую секунду
  const [currentTms, setCurrentTms] = useState(tms);

  // Функция для расчета оставшегося времени
  const calculateTimeLeft = () => {
    const now = new Date(currentTms); // Используем текущее значение currentTms
    let targetTime = new Date(currentTms);
    targetTime.setHours(targetHour, targetMinute, 0, 0);

    // Если текущее время позже целевого, переходим на следующий день
    if (now > targetTime) {
      targetTime.setDate(targetTime.getDate() + 1);
    }

    const difference = targetTime - now;

    // Рассчитываем оставшееся время
    return difference > 0
      ? {
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        }
      : {};
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Обновляем время на основе текущего currentTms каждую секунду
    const timer = setInterval(() => {
      setCurrentTms(prevTms => prevTms + 1000); // Увеличиваем currentTms на 1 секунду
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [currentTms, targetHour, targetMinute]); // Добавляем зависимости

  const formatTime = (value) => String(value).padStart(2, '0');

  return (
    <div>
      {timeLeft.hours !== undefined && (
        <p>
          {formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}
        </p>
      )}
    </div>
  );
};


const Tasks = () => {
  
  const intl = useIntl();

  const [timeTasks, setTimeTasks] = useState([
    { text: intl.formatMessage({ id: 'task_mark' }), targetHour: 0, targetMinute: 0, checked: false, award: 200 },
    { text: intl.formatMessage({ id: 'task_giveaway' }), targetHour: 17, targetMinute: 0, checked: false, award: 200 }
  ])

  const [serverTime, setServerTime] = useState(0)

  const [tasks, setTasks] = useState([])

  const { userData, timerTaskDone, taskDone } = useContext(MainContext)


  useEffect(() => {
    fetch(BACKEND_URL + '/tasks?' + new URLSearchParams({tgid: userData.tgid}).toString())
    .then(response => 
      response.json()
      .then(res => setTasks(res.sort((a, b) => a.checked - b.checked)))
    )
  }, [])
  
  function canUserMarkVisit(lastMarkedTimestamp, tms) {
    const MSK_OFFSET = 3 * 60 * 60 * 1000; // Сдвиг для MSK (UTC+3)
    const now = new Date(tms);
    
    // Получаем текущую дату и устанавливаем время на 17:00 MSK
    const currentDateAt17MSK = new Date(tms);
    currentDateAt17MSK.setUTCHours(14, 0, 0, 0); // 17:00 MSK = 14:00 UTC
    
    // Если текущее время уже 17:00 MSK или позже, проверяем возможность отметки
    if (now.getTime() >= currentDateAt17MSK.getTime()) {
        // Если отметка была сделана до 17:00 текущего дня или не была сделана вовсе
        return lastMarkedTimestamp < currentDateAt17MSK.getTime();
    }
    // Если сейчас еще не 17:00, проверяем отметку на предыдущий день
    const yesterdayAt17MSK = new Date(currentDateAt17MSK.getTime() - 24 * 60 * 60 * 1000);
    return lastMarkedTimestamp < yesterdayAt17MSK.getTime();
}
  

  useEffect(() => {

    fetch(BACKEND_URL + "/time")
    .then(response => response.json())
    .then(res => {
      const now = res.time;
      setServerTime(res.time)
    
      // Определяем 17:00 вчерашнего дня
      const yesterdaySeventeen = new Date(now);
      yesterdaySeventeen.setDate(yesterdaySeventeen.getDate() - 1);
      yesterdaySeventeen.setHours(17, 0, 0, 0);
      const yesterdaySeventeenTimestamp = yesterdaySeventeen.getTime();
    
      // Определяем 17:00 сегодняшнего дня
      const todaySeventeen = new Date(now);
      todaySeventeen.setHours(17, 0, 0, 0);
      const todaySeventeenTimestamp = todaySeventeen.getTime();
    
      setTimeTasks(timeTasks.map(v => {
        // Проверка для задачи с 17:00
        if (v.targetHour === 17) {
          console.log(canUserMarkVisit(userData.last_giveaway_checked, now))
          return { ...v, checked: !canUserMarkVisit(userData.last_giveaway_checked, now) };
        }
    
        // Проверка для задачи, связанной с 00:00
        if (v.targetHour === 0) {
          const startOfDayTimestamp = new Date(todaySeventeenTimestamp).setHours(0, 0, 0, 0);
          const hasCheckedToday = userData.last_site_checked !== 0 && userData.last_site_checked >= startOfDayTimestamp;
          return { ...v, checked: hasCheckedToday && userData.last_site_checked !== 0 };
      }
    
        return v;
      }));
    })
  }, [userData]);
  
  

  const onTimerClick = (checked, is_giveaway, award) => {
    if (!checked) {
      const url = is_giveaway ? 'https://genshindrop.top/give' : 'https://genshindrop.top/checkin';

      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.openLink(url);
      } else {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
      timerTaskDone(award, is_giveaway)
    }
  }

  const onTaskClick = async (task_id, url, award, checked) => {
    if (!checked) {
      if (url.includes('t.me')) {
        const response = await fetch(BACKEND_URL + '/tasks/check', {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            tgid: userData.tgid,
            task_id: task_id
          })
        })

        const result = await response.json()
        
        if (!result.success) {
          if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(url);
          } else {
            window.open(url, '_blank', 'noopener,noreferrer');
          }
          return
        }
      } else {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.openLink(url);
        } else {
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      }
      
      taskDone(task_id, award)
      setTasks(prev => prev.map(v => {
        if (v.id === task_id) v.checked = 1;
        return v
      }))
    }
  }

  return (
    <div className='tasks_root'>

      <div className='tasks_header'>
        <BackButton link='/' />
        <div className='tasks_balance'>
          <HandySvg src='/media/svg/crystal.svg' />
          <p>{userData.score}</p>
        </div>
      </div>

      <div className='tasks_list_wrap'>
        <div className='tasks_list'>
          { 
            timeTasks.map(v => { 
              console.log(v.checked)
              return (
              <div className='task_item'>
                <p>{v.text}</p>
                <div onClick={() => onTimerClick(v.checked, v.targetHour === 17, v.award)} className={`task_item_award ${v.checked && "timer"}`}>
                  { (v.checked) && <Timer targetHour={v.targetHour} targetMinute={v.targetMinute} tms={serverTime} />}
                  { !v.checked &&
                    <>
                      <HandySvg src='/media/svg/crystal.svg' />
                      <p>{v.award}</p>
                    </>
                  }
                </div>
              </div>
            )})
          }

          { 
            tasks.map(v => (
              <div className='task_item'>
                <p>{v.name}</p>
                <div onClick={() => onTaskClick(v.id, v.link, v.score, v.checked)} className={`task_item_award ${(v.checked === 1) && "check"}`}>
                    {(v.checked === 0) && 
                      <>
                        <HandySvg src='/media/svg/crystal.svg' />
                        <p>{v.score}</p>
                      </>
                    }

                    {(v.checked === 1) && <HandySvg src='/media/svg/gift.svg' />}
                    
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Background />
    </div>
  )
}

export default Tasks