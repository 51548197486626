import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Modal.css';
import { HandySvg } from 'handy-svg';

const Modal = ({ text, button, align = 'center', show, onClose, onButtonClick, price=0 }) => {
  const [isVisible, setIsVisible] = useState(show);

  // Анимация с плавным затуханием
  const animation = useSpring({
    opacity: show ? 1 : 0,
    config: { tension: 180, friction: 18 },
    onRest: () => {
      if (!show) setIsVisible(false); // Убираем модальное окно после завершения анимации
    },
  });

  useEffect(() => {
    if (show) setIsVisible(true); // Отображаем окно, если show true
  }, [show]);

  return isVisible ? (
    <animated.div style={animation} className={`modal_root ${align}`} onClick={onClose}>
      <div className={`modal_container_background ${align}`} onClick={(e) => e.stopPropagation()}>
        <div className="modal_container">
          <p>{text}</p>
          {(align !== "center") && 
            <div className='modal_price'>
              <HandySvg src='/media/svg/crystal.svg'/>
              <p>{price}</p>
            </div>
          }
          <p onClick={onButtonClick}>{button}</p>
        </div>
      </div>
    </animated.div>
  ) : null;
};

export default Modal;
