import React, { createContext, useState, useEffect, useRef } from "react";
import { io } from 'socket.io-client';
import { WEBSOCKET_URL } from "../config";
import Modal from "../components/Modal/Modal";
import Loading from "../components/Loading/Loading";

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalText, setModalText] = useState(false);
    const [offDelAward, setOffDelAward] = useState(100);
    const [userData, setUserData] = useState(() => {
        const savedUser = window.localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : undefined;
    });

    const energyIntervalRef = useRef(null);
    const socket = useRef(null);

    const emitSocketEvent = (event, data, fallbackMessage) => {
        if (socket.current && socket.current.connected) {
            socket.current.emit(event, data);
        } else {
            console.error(fallbackMessage || "Сокет не подключен");
        }
    };

    const pluralizeGems = (count, lang) => {
        if (lang === 'ru') {
            const lastDigit = count % 10;
            const lastTwoDigits = count % 100;

            if (lastDigit === 1 && lastTwoDigits !== 11) {
                return 'гем';
            } else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits > 20)) {
                return 'гема';
            } else {
                return 'гемов';
            }
        } else {
            return 'gems';
        }
    };

    useEffect(() => {
        const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id || 440311516;

        socket.current = io(WEBSOCKET_URL, {
            query: `tgid=${userId}`
        });

        socket.current.on('connect', () => {
            console.log("Подключено к серверу Socket.IO");
        });

        socket.current.on('connect_error', (err) => {
            console.error("Ошибка подключения:", err.message);
        });

        socket.current.on('userEnergyUpdate', (user) => {
            if (user && user.tgid === userId) {
                setUserData(user);
            }
        });

        socket.current.on('offline_del', ({ award }) => {
            if (userData) {
                setModalShow(true)
                setModalText(
                    userData.lang === 'ru'
                        ? `Поздравляем! За время вашего отсутствия динозаврик заработал ${award} ${pluralizeGems(award, 'ru')}.`
                        : `Congratulations! During your absence, the dinosaur earned ${award} ${pluralizeGems(award, 'en')}.`
                );
            }
        });

        socket.current.emit('join');

        socket.current.on('userData', (user) => {
            setUserData(user);
        });

        return () => {
            if (socket.current) {
                socket.current.disconnect();
                socket.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (userData) {
            window.localStorage.setItem("user", JSON.stringify(userData));
        }
    }, [userData]);

    useEffect(() => {
        if (userData) {
            const maxEnergy = userData.level * 100;

            if (userData.energy >= maxEnergy && energyIntervalRef.current) {
                clearInterval(energyIntervalRef.current);
                energyIntervalRef.current = null;
                return;
            }

            if (!energyIntervalRef.current && userData.energy < maxEnergy) {
                energyIntervalRef.current = setInterval(() => {
                    setUserData((prev) => {
                        const newEnergy = Math.min(prev.energy + maxEnergy / 3600, maxEnergy);

                        if (newEnergy >= maxEnergy) {
                            clearInterval(energyIntervalRef.current);
                            energyIntervalRef.current = null;
                        }

                        return {
                            ...prev,
                            energy: newEnergy,
                        };
                    });
                }, 1000);
            }
        }

        return () => {
            if (energyIntervalRef.current) {
                clearInterval(energyIntervalRef.current);
                energyIntervalRef.current = null;
            }
        };
    }, [userData]);

    const scoreClick = () => {
        emitSocketEvent('click', { tgid: userData?.tgid }, "Невозможно выполнить действие: сокет не подключен");
        setUserData(prev => ({
            ...prev,
            energy: prev.energy - 1,
            score: prev.score + userData?.level
        }));
    };

    const buylevel = (price) => {
        if (userData.score >= price) {
            emitSocketEvent('buy_level', { price }, "Невозможно купить уровень");
            setUserData(prev => ({
                ...prev,
                level: prev.level + 1,
                score: prev.score - price
            }));
        }
    };

    const buyOffDel = (price) => {
        if (userData.score >= price) {
            emitSocketEvent('buy_off_del', { price }, "Невозможно купить услугу");
            setUserData(prev => ({
                ...prev,
                off_del: 1,
                score: prev.score - price
            }));
        }
    };

    const timerTaskDone = (award, is_giveaway) => {
        emitSocketEvent('timer_task_done', { award, is_giveaway }, "Невозможно завершить задачу");
        setUserData(prev => ({
            ...prev,
            score: prev.score + award,
            ...(is_giveaway ? { last_giveaway_checked: Date.now() } : { last_site_checked: Date.now() })
        }));
    };

    const taskDone = (task_id, award) => {
        emitSocketEvent('task_done', { task_id, award }, "Невозможно завершить задачу");
        setUserData(prev => ({
            ...prev,
            score: prev.score + award
        }));
    };

    const changeLanguage = (language) => {
        emitSocketEvent('change_language', { language }, "Невозможно изменить язык");
        setUserData(prev => ({
            ...prev,
            lang: language
        }));
    };

    const changeNotifications = (is_giveaway) => {
        emitSocketEvent('change_notification', { is_giveaway }, "Невозможно изменить уведомления");
        setUserData(prev => ({
            ...prev,
            notif_giveaway: is_giveaway ? (prev.notif_giveaway === 1 ? 0 : 1) : prev.notif_giveaway,
            notif_site: !is_giveaway ? (prev.notif_site === 1 ? 0 : 1) : prev.notif_site,
        }));
    };

    return (
        <MainContext.Provider value={{
            userData, setUserData, scoreClick, buylevel,
            buyOffDel, timerTaskDone, taskDone, changeLanguage, changeNotifications, setModalShow, setModalText,
            setIsLoading
        }}>
            { !isLoading ?
                <>
                    {children}
                    <Modal
                        text={modalText}
                        button={'OK'}
                        show={modalShow}
                        onClose={() => setModalShow(false)}
                        onButtonClick={() => setModalShow(false)}
                    />
                </> : <Loading />
            }
        </MainContext.Provider>
    );
};
