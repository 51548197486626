import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './CheckBox.css';

const CheckBox = ({ active, onChange }) => {

  const { backgroundColor, translateX } = useSpring({
    backgroundColor: active ? '#12B28C' : '#C2A77F',
    translateX: active ? 53 : 5, // Указываем значения в числовом формате
    config: { duration: 100 },
  });
  

  return (
    <div className="checkBox_root">
      <input
        type="checkbox"
        checked={active}
        onChange={onChange}
        style={{ height: 0, width: 0, visibility: 'hidden' }}
      />
      <label onClick={onChange}>
        <animated.div
          style={{
            backgroundColor,
            width: '75px',
            height: '48px',
            borderRadius: '4px',
            display: 'block',
            position: 'relative',
          }}
        >
          <animated.div
            style={{
              position: 'absolute',
              top: '32%',
              left: translateX,
              width: '16px',
              height: '16px',
              background: '#fff',
              boxShadow: '0px 2px 8px 0px #00000029',
              borderRadius: '90px',
              transition: '0.3s',
            }}
          />
        </animated.div>
      </label>
    </div>
  ); 
};

export default CheckBox;
